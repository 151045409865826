import React from "react";
import "./Permissions.scss";

export enum Permission {
	ADMIN = 1,
	TECNICA = 2,
	JUNTA = 4,
	COMUNICACIO = 8,
	ACCES = 16,
}

enum PermissionString {
	ADMIN = "administrador",
	TECNICA = "tècnica",
	JUNTA = "junta",
	COMUNICACIO = "comunicació",
	ACCES = "accés",
}

function hasPermission(permissions: number, permission: Permission): boolean {
	return !!(permissions & permission);
}

export default class Permissions extends React.Component<{}, {
	interactiveUser: {
		user: {
			id: number,
			name: string,
			email: string,
			permissions?: number,
		},
		type: string,
	} | null,
	permissions: Array<{
		id: number,
		name: string,
		email: string,
		permissions?: number,
	}>
}> {
	constructor(props: {}) {

		console.log(hasPermission(15, Permission.ADMIN));
		super(props);
		this.state = {
			interactiveUser: null,
			permissions: [
				{
					id: 1,
					name: "Aarón Sánchez Jiménez",
					email: "aaron@castellersdemollet.cat",
					permissions: Permission.ADMIN | Permission.TECNICA | Permission.JUNTA | Permission.COMUNICACIO
				},
				{
					id: 2,
					name: "Victor Ruiz Aguilera",
					email: "victor@castellersdemollet.cat",
					permissions: Permission.TECNICA | Permission.JUNTA
				},
				{
					id: 3,
					name: "Roger Parera",
					email: "parera@castellersdemollet.cat",
					permissions: Permission.JUNTA | Permission.ACCES | Permission.COMUNICACIO
				},
				{
					id: 4,
					name: "Rubén Martinez",
					email: "ruben@castellersdemollet.cat",
					permissions: Permission.TECNICA | Permission.ACCES
				},
				{
					id: 5,
					name: "Xavi Avalos",
					email: "xavi@castellersdemollet.cat",
					permissions: Permission.TECNICA
				},
			]
		}
	}

	deleteUser(id: number): void {
		this.setState({
			permissions: this.state.permissions.filter(e => e.id !== id)
		})
	}

	obtenerPermisos(numero: number): Permission[] {
		return Object.keys(Permission).reduce((permisosActivados: Permission[], permiso: string) => {
			const valorPermiso = Permission[permiso];
			if (typeof valorPermiso === 'number' && numero & valorPermiso) {
				permisosActivados.push(valorPermiso);
			}
			return permisosActivados;
		}, []);
	}

	convertirAPermisosBinarios(permisos: Permission[]): number {
		return permisos.reduce((acumulador, permiso) => acumulador | permiso, 0);
	}

	getRandomHexColor() {
		let color;
		do {
			const r = Math.floor(Math.random() * 256);
			const g = Math.floor(Math.random() * 256);
			const b = Math.floor(Math.random() * 256);
			color = '#' + this.toHex(r) + this.toHex(g) + this.toHex(b);
		} while (this.isGrayScale(color) || this.isLightColor(color) || !this.isVibrantColor(color));
		return color;
	}

	toHex(num) {
		const hex = num.toString(16);
		return hex.length === 1 ? '0' + hex : hex;
	}

	isGrayScale(hexColor) {
		const r = parseInt(hexColor.slice(1, 3), 16);
		const g = parseInt(hexColor.slice(3, 5), 16);
		const b = parseInt(hexColor.slice(5, 7), 16);
		return Math.abs(r - g) < 30 && Math.abs(g - b) < 30 && Math.abs(b - r) < 30;
	}
	isLightColor(hexColor) {
		const r = parseInt(hexColor.slice(1, 3), 16);
		const g = parseInt(hexColor.slice(3, 5), 16);
		const b = parseInt(hexColor.slice(5, 7), 16);
		const brightness = (r * 299 + g * 587 + b * 114) / 1000;
		return brightness > 180;
	}
	isVibrantColor(hexColor) {
		const r = parseInt(hexColor.slice(1, 3), 16);
		const g = parseInt(hexColor.slice(3, 5), 16);
		const b = parseInt(hexColor.slice(5, 7), 16);
		return (r > 128 && g > 128) || (g > 128 && b > 128) || (b > 128 && r > 128);
	}

	render(): React.ReactNode {
		console.log(!!(15 & 1))
		return (
			<>
				{
					this.state.interactiveUser && this.state.interactiveUser.type === "delete" ? (
						<div className="modal">
							<div className="content">
								<h2>Eliminar l'accés a {this.state.interactiveUser.user.name}?</h2>
								<div className="buttons">
									<button className="primary" onClick={() => {
										this.setState({
											interactiveUser: null
										})
									}}>Cancelar</button>
									<button className="danger" onClick={() => {
										this.deleteUser(this.state.interactiveUser!.user.id);
										this.setState({
											interactiveUser: null
										})
									}}>Eliminar</button>
								</div>
							</div>
						</div>
					) : null
				}
				{
					this.state.interactiveUser && this.state.interactiveUser.type === "edit" ? (
						<div className="modal">
							<div className="content">
								<h2>Editar permisos de {this.state.interactiveUser.user.name}</h2>
								<div className="permissions">
									{
										Object.keys(Permission).map((permiso, i) => {
											if (typeof Permission[permiso] === "number") {
												return (
													<label key={i}>
														<input type="checkbox" checked={!!(this.state.interactiveUser!.user.permissions! & Permission[permiso])} onChange={(e) => {
															if (e.target.checked) {
																this.state.interactiveUser!.user.permissions! |= Permission[permiso];
															} else {
																this.state.interactiveUser!.user.permissions! &= ~Permission[permiso];
															}
															this.setState({
																interactiveUser: this.state.interactiveUser
															})
														}} />
														<span>{PermissionString[permiso]}</span>
													</label>
												)
											}
											return null;
										})
									}
								</div>
								<div className="properties">
									<label>
										<span>Nom i Cognoms</span>
										<input type="text" value={this.state.interactiveUser.user.name} onChange={(e) => {
											this.state.interactiveUser!.user.name = e.target.value;
											this.setState({
												interactiveUser: this.state.interactiveUser
											})
										}} />
									</label>
									<label>
										<span>Correu Electrònic</span>
										<input type="email" value={this.state.interactiveUser.user.email} onChange={(e) => {
											this.state.interactiveUser!.user.email = e.target.value;
											this.setState({
												interactiveUser: this.state.interactiveUser
											})
										}} />
									</label>
								</div>
								<div className="buttons">
									<button className="primary" onClick={() => {
										this.setState({
											interactiveUser: null,
										})
									}}>Cancelar</button>
									<button className="success" onClick={() => {
										this.setState({
											interactiveUser: null
										})
									}}>Guardar</button>
								</div>
							</div>
						</div>
					) : null
				}
				<div id="permissions">
					<h1>Permisos</h1>
					<table>
						<thead>
							<tr>
								<th>Nom i Cognoms</th>
								<th>Correu Electrònic</th>
								<th>Rols i Permisos</th>
								<th>Accions</th>
							</tr>
						</thead>
						<tbody>
							{
								this.state.permissions.map((e, i) => {
									return (
										<tr key={i}>
											<td><div className="profile"><span className="icon" style={{
												"--color": this.getRandomHexColor(),
											} as React.CSSProperties}></span>{e.name}</div></td>
											<td>{e.email}</td>
											<td>
												<div className="tags">
													{
														e.permissions ? this.obtenerPermisos(e.permissions).map((permiso, i) => {
															return (
																<span key={i} data-type={Permission[permiso].toLowerCase()}>{PermissionString[Permission[permiso]]}</span>
															)
														}) : null
													}
												</div>
											</td>
											<td>
												<div className="buttons">
													<button className="primary" onClick={
														() => {
															this.setState({
																interactiveUser: {
																	user: e,
																	type: "edit"
																}
															})
														}
													}>
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
															<path d="M15.5 12C15.5 13.933 13.933 15.5 12 15.5C10.067 15.5 8.5 13.933 8.5 12C8.5 10.067 10.067 8.5 12 8.5C13.933 8.5 15.5 10.067 15.5 12Z" stroke="#000000" strokeWidth="1.5" />
															<path d="M20.7906 9.15201C21.5969 10.5418 22 11.2366 22 12C22 12.7634 21.5969 13.4582 20.7906 14.848L18.8669 18.1638C18.0638 19.548 17.6623 20.2402 17.0019 20.6201C16.3416 21 15.5402 21 13.9373 21L10.0627 21C8.45982 21 7.6584 21 6.99807 20.6201C6.33774 20.2402 5.93619 19.548 5.13311 18.1638L3.20942 14.848C2.40314 13.4582 2 12.7634 2 12C2 11.2366 2.40314 10.5418 3.20942 9.152L5.13311 5.83621C5.93619 4.45196 6.33774 3.75984 6.99807 3.37992C7.6584 3 8.45982 3 10.0627 3L13.9373 3C15.5402 3 16.3416 3 17.0019 3.37992C17.6623 3.75984 18.0638 4.45197 18.8669 5.83622L20.7906 9.15201Z" stroke="#000000" strokeWidth="1.5" />
														</svg>
													</button>
													<button className="danger" onClick={
														() => {
															this.setState({
																interactiveUser: {
																	user: e,
																	type: "delete"
																}
															})
														}
													}>
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
															<path d="M19.5 5.5L18.8803 15.5251C18.7219 18.0864 18.6428 19.3671 18.0008 20.2879C17.6833 20.7431 17.2747 21.1273 16.8007 21.416C15.8421 22 14.559 22 11.9927 22C9.42312 22 8.1383 22 7.17905 21.4149C6.7048 21.1257 6.296 20.7408 5.97868 20.2848C5.33688 19.3626 5.25945 18.0801 5.10461 15.5152L4.5 5.5" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" />
															<path d="M3 5.5H21M16.0557 5.5L15.3731 4.09173C14.9196 3.15626 14.6928 2.68852 14.3017 2.39681C14.215 2.3321 14.1231 2.27454 14.027 2.2247C13.5939 2 13.0741 2 12.0345 2C10.9688 2 10.436 2 9.99568 2.23412C9.8981 2.28601 9.80498 2.3459 9.71729 2.41317C9.32164 2.7167 9.10063 3.20155 8.65861 4.17126L8.05292 5.5" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" />
															<path d="M9.5 16.5L9.5 10.5" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" />
															<path d="M14.5 16.5L14.5 10.5" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" />
														</svg>
													</button>
												</div>
											</td>
										</tr>
									)
								})
							}
						</tbody>
					</table>
				</div>
			</>
		);
	}
}
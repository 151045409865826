import React from 'react';

import './Cartells.scss';
import QRCode from '../../../components/QRCode';
import html2canvas from "html2canvas";

enum CartellType {
  DinA = 'DinA',
  R16_9 = 'r16x9',
  R4_3 = 'r4x3',
  R1_1 = 'r1x1',
  MINI = 'r47x64',
  PANELL = 'r9x16',
  PANELL_ALT = 'r9x16 alt',
  SOM = 'SOM',
}

/**
- Joyería Venus 🟢
- Pi tapes 
- ARELSA 🟠
- Frankfurt Parera 
- Cal Dibuixant -
- Tendals i Alumini Masip🟢
- D.Victor Serra🟢
- Suma Salut 🟢
- DOG-SI Clinica Veterinaria🟢
- Pollastres MiT  🟢
- Plaça Döner Kebab
- Luis Utrilla 🟢 petro instal
- Inst. J.Bueno🟢
 */

export class Patrocinadors extends React.Component {
  render(): React.ReactNode {
    return (
      <div className="content">
        <h1>
          <span className="colla">
            CASTELLERS DE <b>MOLLET</b>
          </span>
          <div className="logo">
            <span className="al">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -10 8 10">
                <path d="M 0 0 L 2 0 L 4 -5 L 6 0 L 8 0 L 4 -10 L 0 0" fill="#EB6C20" />
              </svg>
              L
            </span>
            <span className='title'>
              <span className="first"><b>Concurs</b>de</span>
              <span className="second"><b>C<span className="rotated">V</span>stells</b><strong>24</strong></span>
            </span>
          </div>
        </h1>
        <ul className="patrocinadors">
          <li>
            <img src="/assets/partners/SumaSalut.jpeg" alt="Suma Salut" />
          </li>
          <li>
            <img src="/assets/partners/Logo-Tendalls-Masip.svg" alt="Tendalls Masip" />
          </li>
          <li>
            <img src="/assets/partners/Polleries-MiT-Samarreta-TAP.svg" alt="Polleria Marible i Tomas" />
          </li>
          <li>
            <img src="/assets/partners/LOGO COLOR PETROINSTAL.PNG" alt="PETROINSTAL" />
          </li>
          <li>
            <img src="/assets/partners/Venuslogo.webp" alt="Joieria Venus" />
          </li>
          <li>
            <img src="/assets/partners/Logo-with-clinica-white.webp" alt="" style={{ filter: 'grayscale(100%) invert(1)' }} />
          </li>
          <li>
            <img src="/assets/partners/Jose Bueno Original.png" alt="Instalaciones Jose Bueno" />
          </li>
          <li>
            <img src="/assets/partners/DOG-SI.jpeg" alt="DOG-SI Clinica Veterinaria" />
          </li>
          <li>
            <img src="/assets/partners/ARELSA negre.png" alt="Arelsa" />
          </li>
          <li>
            <img src="/assets/partners/PiTapes.svg" alt="PiTapes" />
          </li>
          <li>
            <img src="/assets/partners/Frankfurt Parera original.png" alt="Frankfurt Parera" />
          </li>
          <li>
            <img src="/assets/partners/DonerKebab.webp" alt="Doner Kebab La Plaza" />
          </li>
          <li>
            <img src="/assets/partners/nexo.jpeg" alt="" />
          </li>
          <li>
            <img src="/assets/partners/Restaurant Xinès Pequín Q.svg" alt="" />
          </li>
          <li>
            CAL DIBUIXANT SORT
          </li>
        </ul>
        <div className="tap">
          <p>Apunta't entrant a l'enllaç de baix o escanejant el QR. Et donarem samarreta corporativa i transport gratuïts.</p>
        </div>
        <img src="/svg/logo3D.svg" alt="Escut" className="logo" />
        <span className="url">castellersdemollet.cat/concurs</span>
        <div className="qr">
          <QRCode text={'https://castellersdemollet.cat/concurs'} />
        </div>
      </div>
    )
  }
}

export class Informacio extends React.Component<{ type: CartellType }> {
  render(): React.ReactNode {
    return (
      <div className="content">
        <h1>
          <span className="colla">
            CASTELLERS DE <b>MOLLET</b>
          </span>
          <div className="logo">
            <span className="al">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -10 8 10">
                <path d="M 0 0 L 2 0 L 4 -5 L 6 0 L 8 0 L 4 -10 L 0 0" fill="#EB6C20" />
              </svg>
              L
            </span>
            <span className='title'>
              <span className="first"><b>Concurs</b>de</span>
              <span className="second"><b>C<span className="rotated">V</span>stells</b><strong>24</strong></span>
            </span>
          </div>
        </h1>
        <ul className="assajos">
          <li>
            <span className="day">Dimarts</span>
            <span className="dies">24/09 i 01/10</span>
            <span className="time">20:00 - 22:00</span>
            <span className="place">C.C. L'Era</span>
          </li>
          <li>
            <span className="day">Dimecres</span>
            <span className="dies">25/09 i 02/10</span>
            <span className="time">20:00 - 22:00</span>
            <span className="place">C.C. L'Era</span>
          </li>
          <li>
            <span className="day">Divendres</span>
            <span className="dies">27/09 i 04/10</span>
            <span className="time">20:30 - 23:00</span>
            <span className="place">C.C. L'Era</span>
          </li>
        </ul>
        <svg
          fill="#EB6C20"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-41.53 -41.53 498.32 498.32"
          stroke="#EB6C20"
          strokeWidth="0.00415262"
        >
          <g strokeWidth="0"></g>
          <g strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="4.983144"></g>
          <g>
            <g>
              <path d="M414.937,374.984c-7.956-24.479-20.196-47.736-30.601-70.992c-1.224-3.06-6.12-3.06-7.956-1.224 c-10.403,11.016-22.031,22.032-28.764,35.496h-0.612c-74.664,5.508-146.88-58.141-198.288-104.652 c-59.364-53.244-113.22-118.116-134.64-195.84c-1.224-9.792-2.448-20.196-2.448-30.6c0-4.896-6.732-4.896-7.344,0 c0,1.836,0,3.672,0,5.508C1.836,12.68,0,14.516,0,17.576c0.612,6.732,2.448,13.464,3.672,20.196 C8.568,203.624,173.808,363.356,335.376,373.76c-5.508,9.792-10.403,20.195-16.523,29.988c-3.061,4.283,1.836,8.567,6.12,7.955 c30.6-4.283,58.14-18.972,86.292-29.987C413.712,381.104,416.16,378.656,414.937,374.984z M332.928,399.464 c3.673-7.956,6.12-15.912,10.404-23.868c1.225-3.061-0.612-5.508-2.448-6.12c0-1.836-1.224-3.061-3.06-3.672 c-146.268-24.48-264.996-124.236-309.06-259.489c28.764,53.244,72.828,99.756,116.28,138.924 c31.824,28.765,65.484,54.468,102.204,75.888c28.764,16.524,64.872,31.824,97.92,21.421l0,0c-1.836,4.896,5.508,7.344,7.956,3.672 c7.956-10.404,15.912-20.196,24.48-29.376c8.567,18.972,17.748,37.943,24.479,57.527 C379.44,382.94,356.796,393.956,332.928,399.464z"></path>
            </g>
          </g>
        </svg>
        <div className="tap">
          <h2>VOLS VENIR A OMPLIR LA TAP DE <b>VERD CLARET</b>?</h2>
          {
            this.props.type === CartellType.PANELL || this.props.type === CartellType.SOM ? <p className='noMaxWidth'>Vine als assajos previs al Concurs de Castells</p> : <p className='noMaxWidth'>Dissabte 5 d'Octubre a la Tarraco Arena Plaça, Tarragona</p>
          }
          <div className="info">
            <div className="sortida">
              <h3>Sortida</h3>
              <span className="time">12:00</span>
              <span className="place">Pavelló Municipal Riera Seca (Hoquei)</span>
            </div>
            <div className="arena">
              <h3>Tarraco Arena Plaça</h3>
              <div className="time">
                <h4>Entrada a Plaça</h4>
                <span>16:30<hr /></span>
              </div>
              <div className="time">
                <h4>Convocatòria</h4>
                <span>14:45<hr /></span>
              </div>
            </div>
          </div>
          <p>Apunta't entrant a l'enllaç de baix o escanejant el QR. Et donarem samarreta corporativa{this.props.type === CartellType.PANELL || this.props.type === CartellType.SOM ? '' : 'i transport gratuïts'}.</p>
        </div>
        <img src="/svg/logo3D.svg" alt="Escut" className="logo" />
        <span className="url">castellersdemollet.cat/concurs</span>
        <div className="qr">
          <QRCode text={'https://castellersdemollet.cat/concurs'} />
        </div>
      </div>
    )
  }
}

export default class Cartells extends React.Component<{}, { type: CartellType, partners: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = {
      type: CartellType.R1_1,
      partners: false,
    };
    this.downloadCartell = this.downloadCartell.bind(this);
  }

  downloadCartell = () => {
    const element = document.getElementById(this.state.type);
    if (!element) return;
    // https://html2canvas.hertzen.com/configuration/
    html2canvas(element, {
      scale: 4,
    }).then((canvas) => {
      const link = document.createElement('a');
      link.download = 'cartell.png';
      link.href = canvas.toDataURL('image/png');
      link.click();
    });
  }

  componentDidMount() {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'd') this.downloadCartell();
    });
  }

  componentWillUnmount() {
    // Remove the event listener when the component is unmounted
    document.removeEventListener('keydown', (e) => {
      if (e.key === 'd') this.downloadCartell();
    });
    document.removeEventListener('keydown', this.downloadCartell);
  }

  render() {
    return (
      <div id={this.state.type} className="cartell alt">
        {
          this.state.partners ? <Patrocinadors /> : <Informacio type={this.state.type} />
        }
      </div>
    );
  }
}
export const Quotes: { [key: string]: boolean; } = {
  47719136: true,
  54165503: true,
  34738909: true,
  37382040: false,
  77118914: true,
  77118915: true,
  52178003: true,
  77320497: false,
  53340159: true,
  77121309: true,
  47807942: false,
  46551171: false,
  53652113: true,
  77277464: true,
  23893053: true,
  53650020: true,
  53650021: false,
  36520255: true,
  77129200: false,
  77126026: false,
  53334171: true,
  53334170: true,
  41644311: true,
  46805701: true,
  49808375: false,
  54174991: false,
  77097112: true,
  77125691: true,
  77123468: false,
  77123469: false,
  47724986: false,
  46943234: true,
  46943235: true,
  46748614: true,
  49643230: true,
  33886707: true,
  47910534: false,
  77127985: true,
  53029113: true,
  47915492: false,
  47237953: true,
  47154141: true,
  77118238: false,
  20554157: true,
  38143479: false,
  34762401: false,
  46914429: false,
  77123149: true,
  33906662: true,
  53028063: false,
  54167211: false,
  47646631: true,
  47646632: true,
  53032975: false,
  77117999: true,
  77124628: true,
  77122075: true,
  36525259: true,
  35061253: true,
  47878785: true,
  53654205: false,
  45830797: true,
  52179366: true,
  77129019: false,
  77123864: true,
  38129411: true,
  43453492: true,
  52439223: false,
  73384731: true,
  54252136: true,
  25367078: false,
  25367079: false,
  77126872: true,
  41626226: false,
  47977338: false,
  77122562: true,
  47728986: true,
  47236402: false,
  34766668: true,
  46129355: false,
  47970359: true,
  90002553: true,
  77124599: true,
  41625746: true,
  35073392: true,
  46943771: true,
  47573229: true,
  53652645: false,
  40986493: true,
  53081773: true,
  38394305: true,
  54453626: false,
  43527724: true,
  46824388: false,
  54037465: false,
  77122386: true,
  52405746: true,
  38112848: true,
  53650527: true,
  47576634: false,
  47576635: false,
  46945120: true,
  46965361: true,
  52167517: false,
  77310815: true,
  18039221: true,
  53034838: false,
  43536112: true,
  43507484: true,
  52437267: true,
  46143001: true,
  36961080: true,
  39532805: true,
  46760669: true,
  77127732: false,
  46963632: true,
  79273711: true,
  54451263: true,
  53651107: false,
  43686899: true,
  52397498: true,
  47274622: true,
  47998306: true,
  77126886: false,
  77126996: true,
  49716219: true,
  49716220: true,
  42437507: true,
  37292882: true,
  47893203: true,
  38101709: true,
  47706999: true,
  47965893: false,
  48102033: false,
  49360727: true,
  49360729: true,
  52438748: true,
  53650623: true,
  43417311: true,
  52439118: true,
  52439214: true,
  20556566: true,
  37727450: true,
  44988897: true,
  47703752: true,
  47709592: true,
  33889795: false,
  77127204: true,
  42689084: true,
  77128036: true,
  20553807: true,
  53027525: true,
  47874263: true,
  49361493: false,
  44022195: true,
  20041742: true,
  41527674: true,
  47707738: true,
  52178769: true,
  53125311: true,
  54165102: false,
  34732139: false,
  47706678: true,
  79271554: false,
  53653426: false,
  14270856: true,
  54166412: false,
  36943280: false,
  49298811: true,
  46607776: false,
  53033633: true,
  77306480: false,
  46022921: true,
  52437232: false,
  53654413: true,
  53123435: false,
  53224982: false,
  46062500: false,
  77119153: true,
  77314088: false,
  52401634: true,
  53031607: true,
  43550232: true,
  53026679: false,
  46763040: true,
  34765777: true,
  53031443: true,
  53653113: true,
  52170591: false,
  47705725: true,
  52438471: true,
  47887195: true,
  77113207: true,
  52393974: false,
  54038019: false,
  49990641: false,
  49990642: false,
  53124366: false,
  53836759: false,
  77117892: true,
  41003160: true,
  38496956: true,
  77125537: true,
  52400994: false,
  77121476: true,
  53030050: false,
  46696193: true,
  53032086: true,
  46698001: false,
  20553494: true,
  46763754: true,
  54452623: false,
  38111314: true,
  52929932: true,
  36526784: true,
  77127030: false,
  77127031: false,
  52158129: false,
  53122752: false,
  20557663: true,
  49635478: true,
  37328723: true,
  47701634: false,
  77310832: false,
  54927177: false,
  54927178: false,
  77320585: true,
  77122128: false,
  46575777: true,
  53727260: true,
  47701180: true,
  53838901: false,
  53033384: true,
  31921761: true,
  41626076: false,
  38121578: true,
  47780432: true,
  49361136: true,
  49361137: true,
  52439693: true,
  52439351: true,
  48269244: true,
  77124631: false,
  46702090: true,
  77126866: true,
  43509480: true,
  40970431: true,
  77124159: true,
  77128729: true,
  35028647: true,
  53028026: false,
  49364621: false,
  53026241: false,
  47923187: true,
  35077968: true,
  77310843: true,
  53032744: true,
  77124076: false,
  77120386: true,
  46766428: false,
  54249023: false,
  79279419: true,
  38796518: true,
  77111799: true,
  54252431: false,
  53025862: true,
  54456281: true,
  52917914: true,
  43204224: true,
}
import { useEffect, useRef } from 'react';
import QRCode from 'qrcode';

const QRCodeComponent = ({ text, size = 256 }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (text && canvasRef.current) {
      QRCode.toCanvas(canvasRef.current, text, { width: size }, (error) => {
        if (error) console.error(error);
      });
    }
  }, [text, size]);

  return <canvas ref={canvasRef} className='QR'/>;
};

export default QRCodeComponent;